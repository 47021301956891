import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

let router = new Router({
  mode: 'hash',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes: [
    //Auth
      {
        name:'Auth',
        path:'/auth',
        component : () => import('@/views/auth/Login'),
      },
      //Logout
      {
        name:'Logout',
        path:'/logout',
        component : () => import('@/views/auth/Logout'),
        
      },
     {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },

        // Pages
        {
          name: 'Category',
          path: '/category',
        
          component: () => import('@/views/dashboard/pages/Category'),
        },
        {
          name: 'Farmers',
          path: '/farmers',
        
          component: () => import('@/views/dashboard/pages/Farmer'),
        },
        {
          name: 'Farmer Dashboard',
          path: 'farmers/user-data',
        
          component: () => import('@/views/dashboard/pages/UserData'),
        },
        {
          name: 'File Manager',
          path: '/file-manager',

          component: () => import('@/views/dashboard/pages/FileManager'),
        },
        {
            name: 'News',
            path: '/news',

            component: () => import('@/views/dashboard/pages/News'),
        },
        {
          name: 'Videos',
          path: '/videos',

          component: () => import('@/views/dashboard/pages/Videos'),
        },
        {
          name: 'Communications',
          path: '/communications',

          component: () => import('@/views/dashboard/pages/Communications'),
        },

        
      ],
    },

  ],
});
router.beforeEach((to, from, next) => {
  if ( to.matched.some(record => (record.name !== 'Auth' && record.name !== 'Logout' ) )  
  && !store.modules.admin.state().token){
      next('/auth');
      return;
  }
  next();
})

export default router;



