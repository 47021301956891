// store modules
import admin from './store/admin/store';
import category from './store/category/store';
import farmer from './store/farmer/store';
import permissions from './store/permissions/store';
import news from './store/news/store';
import media from './store/media/store';

import tree from './store/tree/store';
import modal from './store/modal/store';
import settings from './store/settings/store';
import manager from './store/manager/store';
import messages from './store/messages/store';
import notifications from './store/notifications/store';
import fileaccess from './store/fileaccess/store';
import pageaccess from './store/pageaccess/store';
import searchtags from './store/search-tags/store';

import state from './store/state';
import mutations from './store/mutations';
import getters from './store/getters';
import actions from './store/actions';

export default {
  namespaced : true,
  modules:{
    admin,
    category,
    farmer,
    news,
    media,
    fileaccess,
    pageaccess,
    permissions,
    notifications,
    settings,
    left: manager,
    right: manager,
    tree,
    modal,
    messages,
    searchtags
  },
  state,
  mutations,
  getters,
  actions,

}



