/**
 * Italian translate
 * Ezio
 * @type Object
 */
const it = {
  btn: {
    about: 'Informazioni',
    back: 'Indietro',
    cancel: 'Annulla',
    clear: 'Pulisci',
    copy: 'Copia',
    cut: 'Taglia',
    delete: 'Elimina',
    edit: 'Modifica',
    forward: 'Inoltra',
    folder: 'Nuova Cartella',
    file: 'Nuovo File',
    fullScreen: 'Visualizzazione a schermo intero',
    grid: 'Griglia',
    paste: 'Incolla',
    refresh: 'Ricarica',
    submit: 'Conferma',
    table: 'Tabella',
    upload: 'Upload',
    uploadSelect: 'Seleziona files',
    hidden: ' Files Nascosti',
  },
  clipboard: {
    actionType: 'Tipo',
    copy: 'Copia',
    cut: 'Taglia',
    none: 'Nessun elemento selezionato',
    title: 'Appunti',
  },
  contextMenu: {
    copy: 'Copia',
    cut: 'Taglia',
    delete: 'Elimina',
    download: 'Scarica',
    info: 'Selezionati:',
    open: 'Apri',
    paste: 'Incolla',
    properties: 'Proprietà',
    rename: 'Rinomina',
    select: 'Seleziona',
    view: 'Anteprima',
    zip: 'Zip',
    unzip: 'Estrai zip',
    edit: 'Modifica',
    audioPlay: 'Play',
    videoPlay: 'Play',
  },
  info: {
    directories: 'Cartelle:',
    files: 'Files:',
    selected: 'Selezionati:',
    selectedSize: 'Dimensione files:',
    size: 'Dimensione files:',
  },
  manager: {
    table: {
      date: 'Data',
      folder: 'Cartella',
      name: 'Nome',
      size: 'Dim.',
      type: 'Tipo',
    },
  },
  modal: {
    about: {
      developer: 'Developer',
      name: 'Laravel File Manager',
      title: 'Informazioni',
      version: 'Versione',
    },
    delete: {
      noSelected: 'Nessuna selezione!',
      title: 'Elimina',
    },
    newFile: {
      fieldName: 'Nome del file',
      fieldFeedback: 'Il file esiste!',
      title: 'Crea nuovo file',
    },
    newFolder: {
      fieldName: 'Nome cartella',
      fieldFeedback: 'La cartella esiste!',
      title: 'Crea nuova cartella',
    },
    preview: {
      title: 'Anteprima',
    },
    properties: {
      disk: 'Disco',
      modified: 'Mod.',
      name: 'Nome',
      path: 'Percorso',
      size: 'Dim.',
      title: 'Proprietà',
      type: 'Tipo',
      url: 'URL',
      access: 'Accesso',
      access_0: 'Accesso negato',
      access_1: 'Sola lettura',
      access_2: 'Lettura e Scrittura',
    },
    rename: {
      directoryExist: 'La cartella esiste',
      fieldName: 'Inserisci il nome',
      fieldFeedback: 'Nome non valido',
      fileExist: 'Il file esiste',
      title: 'Rinomina',
    },
    status: {
      noErrors: 'Nessun errore!',
      title: 'Stato',
    },
    upload: {
      ifExist: 'Il file esiste:',
      noSelected: 'Nessun files selezionato!',
      overwrite: 'Sovrascrivi!',
      selected: 'Selezionato:',
      size: 'Dim.:',
      skip: 'Salta',
      title: 'Carica files',
    },
    editor: {
      title: 'Editor',
    },
    audioPlayer: {
      title: 'Player Audio',
    },
    videoPlayer: {
      title: 'Player Video',
    },
    zip: {
      title: 'Crea Archivio',
      fieldName: 'Nome Archivio',
      fieldFeedback: 'Archivio esistente!',
    },
    unzip: {
      title: 'Estrai archivio',
      fieldName: 'Nom cartella',
      fieldRadioName: 'Estrai in:',
      fieldRadio1: 'Nella cartella corrente',
      fieldRadio2: 'In una nuova cartella',
      fieldFeedback: 'Cartella esistente!',
      warning: 'Attenzione! se i nomi dei file coincidono i file presenti verranno sovrascritti!',
    },
    cropper: {
      title: 'Ritaglia',
      apply: 'Applica',
      reset: 'Reset',
      save: 'Salva',
    },
  },
  notifications: {
    cutToClipboard: 'Tagliato e salvato negli appunti!',
    copyToClipboard: 'Copiato negli appunti!',
  },
  response: {
    noConfig: 'File di configuraizone non trovato!',
    notFound: 'Non trovato!',
    diskNotFound: 'Disco non trovato!',
    pathNotFound: 'Cartella non trovata!',
    diskSelected: 'Disco selezionato!',
    // files
    fileExist: 'File già esistente!',
    fileCreated: 'File creato!',
    fileUpdated: 'File caricato!',
    fileNotFound: 'File non trovato!',
    // directories
    dirExist: 'La cartella è già presente!',
    dirCreated: 'Cartella creata!',
    dirNotFound: 'Cartella non trovata',
    // actions
    uploaded: 'Tutti i file sono stati caricati!',
    notAllUploaded: 'Alcuni file NON sono stati caricati!',
    delNotFound: 'Alcuni elementi NON sono stati trovati!',
    deleted: 'Eliminato!',
    renamed: 'Rinominato!',
    copied: 'Copiato con successo!',
    // zip
    zipError: "Errore dirante la creazione dell'archivio!",
    // acl
    aclError: 'Accesso negato!',
  },
};

export default it;
