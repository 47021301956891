import mutations from './mutations';
import getters from './getters';
import actions from './actions';


export default {
    namespaced: true,
    state(){
        return {
            status: '',
            token: localStorage.getItem('LoggedUser') || '',
            user: {}
        };
    },
    getters,
    mutations,
    actions,
   
}