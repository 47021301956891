import  axios from 'axios';
export default {

    categoriesForFile({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`farmer/file-category?full_path=${params.full_path}` )
                .then(resp => {
                    if(resp.data.success === 0 ){
                        reject(resp.data.error)
                    } else {

                        resolve(resp.data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    savePermission({commit}, aclForm) {
        return new Promise((resolve, reject) => {
            axios.post(`farmer/file-category`, aclForm )
                .then(resp => {
                    if(resp.data.success === 0 ){
                        reject(resp.data.error)
                    } else {
                        resolve(resp.data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })

    }

};
