
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state(){
    return {
      accessLog:[],
      userAccess:[],
      lastWeekCount: 0,
      weekly: {},
    
    }
    
  },
  actions,
  mutations
};
