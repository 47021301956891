import axios from "axios"

export default {
   
  list({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('farmer/media-links')
      .then(resp => {
        if(resp.data.success === 0 ){
            reject(resp.data.error)
        } else {
          commit('setLinks',{ response: resp.data })
          resolve(resp)
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  medias({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('farmer/medias')
      .then(resp => {
        if(resp.data.success === 0 ){
            reject(resp.data.error)
        } else {
          commit('setMedias',{ response: resp.data })
          resolve(resp.data)
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },

  get({commit}, form) {
    return new Promise((resolve, reject) => {
      axios.get(`farmer/media-links/${form.id}`)
          .then(resp => {
            if(resp.data.success === 0 ){
              reject(resp.data.error)
            } else {
              //commit('setForm', resp.data)
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
    })
  },
  store({commit}, form) {
    return new Promise((resolve, reject) => {
      axios.post(`farmer/media-links`, form)
          .then(resp => {
            if(resp.data.success === 0 ){
              reject(resp.data.error)
            } else {
              resolve(resp)
            }
          })
          .catch(err => {
            reject(err)
          })
    })
  },
  destroy({commit}, form) {
    return new Promise((resolve, reject) => {
      axios.delete(`farmer/media-links/${form.id}`)
          .then(resp => {
            if (resp.data.success === 0) {
              reject(resp.data.error)
            } else {
              resolve(resp)
            }
          })
          .catch(err => {
            reject(err)
          })
    })
  },
  
  update({commit}, form) {
      return new Promise((resolve, reject) => {
        axios.put(`farmer/media-links/${form.id}`, form)
            .then(resp => {
              if(resp.data.success === 0 ){
                reject(resp.data.error)
              } else {
                resolve(resp.data)
              }
            })
            .catch(err => {
              reject(err)
            })
      })
},


}
