import mutations from './mutations';
import actions from './actions';


export default {
    namespaced: true,
    state(){
        return {
           links:[],
           medias:[],
           loading: true
        };
    },
    mutations,
    actions,
   
}