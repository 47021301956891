import axios from "axios"

export default {
    countAccess({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('farmer/page-access/count')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('setAccessLog', {response:resp.data})
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })

  
    },
    lastWeekAccess({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('farmer/page-access/last-week')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('lastWeekCount', {response:resp.data.total})
            resolve(resp.data.ct)
          }
        })
        .catch(err => {
          reject(err)
        })
      })

  
    },
    weeklyAccess({commit}){
      return new Promise((resolve, reject) => {
        axios.get('farmer/page-access/weekly')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('weeklyData', {response:resp.data})
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })


    },
    getUserAccess({commit}, farmer){
      return new Promise((resolve, reject) => {
        axios.get(`farmer/page-access/user_access/${farmer.id}`)
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('userAccess', {response:resp.data})
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })


    }
   
   
}