import axios from "axios"

export default {
    topDownloads({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('farmer/fileaccess-log/count-downloads')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('setFileDownloads', {response:resp.data})
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })

  
    },
    downloaders({commit}){
      return new Promise((resolve, reject) => {
        axios.get('farmer/fileaccess-log/top-downloads')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('setTopDownloaders', {response:resp.data})
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })

    },
    countSharedFiles({commit}){
      return new Promise((resolve, reject) => {
        axios.get('farmer/fileaccess-log/count-shared')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('countShared', {response:resp.data})
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
     },
     weeklyShare({commit}){
      return new Promise((resolve, reject) => {
        axios.get('farmer/fileaccess-log/weekly_shares')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    
    },
    weeklyDownloads({commit}){
      return new Promise((resolve, reject) => {
        axios.get('farmer/fileaccess-log/weekly_downloads')
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    
    },
  

    getUserDownloads({commit}, farmer){
      return new Promise((resolve, reject) => {
        axios.get(`farmer/fileaccess-log/user_downloads/${farmer.id}`)
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
          //  alert(resp.data.length.toString());
            commit('userDownloads', resp.data);
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
      
    
    },
    getUserShares({commit}, farmer){
      return new Promise((resolve, reject) => {
        axios.get(`farmer/fileaccess-log/user_sharing/${farmer.id}`)
        .then(resp => {
          if(resp.data.success === 0 ){
              reject(resp.data.error)
          } else {
            commit('userSharing', resp.data);
            resolve(resp.data)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
      
    
    }

     
  }