export default {

    FARMERS_REQUEST(state) {
        state.status = 'loading'
    },
    FARMERS_REQUEST_SUCCESS(state, payload) {
        state.status = 'success'
        state.farmers = payload.response
        state.loading = payload.response.length === 0;
    },
    FARMERS_REQUEST_ERROR(state) {
        state.status = 'error'
    },
    LOGIN_LOGS(state, payload) {
        state.login_logs = payload.response
       
    },
   
}
