import  axios from 'axios';
export default {

    allTags({commit}){
        return new Promise((resolve, reject) => {
            axios.get(`farmer/search-tags` )
                .then(resp => {
                    if(resp.data.success === 0 ){
                        reject(resp.data.error)
                    } else {
                        resolve(resp.data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    addTag({commit}, form){
        console.log(form);
        return new Promise((resolve, reject) => {
            axios.post(`farmer/search-tags`, form)
                .then(resp => {
                    console.log(resp);
                    
                    if(resp.data.success === 0 ){
                        reject(resp.data.error)
                    } else {
                        resolve(resp.data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    removeTag({commit}, form){
        return new Promise((resolve, reject) => {
            axios.post(`farmer/search-tags/remove`, form)
                .then(resp => {
                    if(resp.data.success === 0 ){
                        reject(resp.data.error)
                    } else {
                        resolve(resp.data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    

};
