export default {
    setTopDownloaders(state, payload) {
        state.topDownloaders = payload.response;
    },
    setFileDownloads(state, payload) {
        state.fileDownloads = payload.response;
    },
    countShared(state, payload) {
        state.fileShared = payload.response;
    },
    userDownloads(state, payload) {
        state.userDownloads = payload.response;
    },
    userSharing(state, payload) {
        state.userSharing = payload.response;
    },

    
    
}
