export default {

    CATEGORY_REQUEST(state) {
        state.status = 'loading'
    },
    CATEGORY_REQUEST_SUCCESS(state, payload) {
        state.status = 'success'
        state.categories = payload.response
    },
    CATEGORY_REQUEST_ERROR(state) {
        state.status = 'error'
    },


   
}
