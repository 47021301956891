import axios from "axios"

export default {
    list({commit}) {
        return new Promise((resolve, reject) => {
          axios.get('farmer/messages')
          .then(resp => {
            console.log( resp.data);
            commit('setMessages',{ response: resp.data })
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      send({commit}, form) {
        return new Promise((resolve,reject) => {
          axios.post('farmer/messages/send', form)
          .then(resp => {
            console.log( resp.data);
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
        });

      }   

      
      

}
