import mutations from './mutations';
import getters from './getters';
import actions from './actions';


export default {
    namespaced: true,
    state(){
        return {
            farmers: [],
            login_logs: [],
            loading: true,
           
        };
    },
    getters,
    mutations,
    actions,
   
}
