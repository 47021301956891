export default {
    setAccessLog(state, payload) {
        state.accessLog = payload.response;
    },
    lastWeekCount(state, payload){
        state.lastWeekCount = payload.response;
    },
    weeklyData(state, payload){
        state.weekly = payload.response;
    },
    userAccess(state, payload){
        state.userAccess = payload.response;
    },
}
