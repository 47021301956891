export default {
    setNews(state, payload) {


        state.news = payload.response
        state.loading = payload.response === null;
    },
    setForm(state, payload) {
        state.form = payload.response
    }
   
}
