export default {

    setLinks(state, payload) {

        state.links = payload.response
        state.loading = payload.response === null;
    },

    setMedias(state, payload) {
        state.medias = payload.response
    },

   
}
