/**
 * Czech translate
 * Aleš Nejdr - mige
 * @type Object
 */
const cs = {
  btn: {
    about: 'O aplikaci',
    back: 'Zpět',
    cancel: 'Zrušit',
    clear: 'Vymazat',
    copy: 'Kopírovat',
    cut: 'Vyjmout',
    delete: 'Smazat',
    edit: 'Upravit',
    forward: 'Vpřed',
    folder: 'Nová složka',
    file: 'Nový soubor',
    fullScreen: 'Celá obrazovka',
    grid: 'Mřížka',
    paste: 'Vložit',
    refresh: 'Obnovit',
    submit: 'Odeslat',
    table: 'Tabulka',
    upload: 'Nahrát',
    uploadSelect: 'Vybrat soubory',
    hidden: ' Skryté soubory',
  },
  clipboard: {
    actionType: 'Typ',
    copy: 'Kopírovat',
    cut: 'Vyjmout',
    none: 'Nic nevybráno',
    title: 'Schránka',
  },
  contextMenu: {
    copy: 'Kopírovat',
    cut: 'Vyjmout',
    delete: 'Smazat',
    download: 'Stáhnout',
    info: 'Vybráno:',
    open: 'Otevřit',
    paste: 'Vložit',
    properties: 'Vlastnosti',
    rename: 'Přejmenovat',
    select: 'Vybrat',
    view: 'Zobrazit',
    zip: 'Zabalit (Zip)',
    unzip: 'Rozbalit (Zip)',
    edit: 'Upravit',
    audioPlay: 'Přehrát',
    videoPlay: 'Přehrát',
  },
  info: {
    directories: 'Složky:',
    files: 'Soubory:',
    selected: 'Vybráno:',
    selectedSize: 'Velikost souborů:',
    size: 'Velikost souborů:',
  },
  manager: {
    table: {
      date: 'Změněno',
      folder: 'Složka',
      name: 'Název',
      size: 'Velikost',
      type: 'Typ',
    },
  },
  modal: {
    about: {
      developer: 'Vývojář',
      name: 'Laravel File Manager',
      title: 'O aplikaci',
      version: 'Verze',
    },
    delete: {
      noSelected: 'Nic nevybráno!',
      title: 'Smazat',
    },
    newFile: {
      fieldName: 'Název souboru',
      fieldFeedback: 'Soubor již existuje!',
      title: 'Vytvořit nový soubor',
    },
    newFolder: {
      fieldName: 'Název složky',
      fieldFeedback: 'Složka již existuje!',
      title: 'Vytvořit novou složku',
    },
    preview: {
      title: 'Náhled',
    },
    properties: {
      disk: 'Disk',
      modified: 'Změněno',
      name: 'Název',
      path: 'Cesta',
      size: 'Velikost',
      title: 'Vlastnosti',
      type: 'Typ',
      url: 'URL',
      access: 'Přístup',
      access_0: 'Přístup odepřen',
      access_1: 'Pouze ke čtení',
      access_2: 'Čtení a zápis',
    },
    rename: {
      directoryExist: 'Složka již existuje',
      fieldName: 'Vložte nové jméno',
      fieldFeedback: 'Neplatné jméno',
      fileExist: 'Soubor již existuje',
      title: 'Přejmenovat',
    },
    status: {
      noErrors: 'Žádná chyba!',
      title: 'Stav',
    },
    upload: {
      ifExist: 'Pokud soubor již existuje:',
      noSelected: 'Nevybrány žádné soubory!',
      overwrite: 'Přepsat!',
      selected: 'Vybráno:',
      size: 'Velikost:',
      skip: 'Přeskočit',
      title: 'Nahrát soubory',
    },
    editor: {
      title: 'Editor',
    },
    audioPlayer: {
      title: 'Audio přehrávač',
    },
    videoPlayer: {
      title: 'Video přehrávač',
    },
    zip: {
      title: 'Vytvořit archiv',
      fieldName: 'Název archivu',
      fieldFeedback: 'Archiv již existuje!',
    },
    unzip: {
      title: 'Rozbalit archiv',
      fieldName: 'Název složky',
      fieldRadioName: 'Rozbalit do:',
      fieldRadio1: 'Do aktuální složky',
      fieldRadio2: 'Do nové složky',
      fieldFeedback: 'Složka již existuje!',
      warning: 'Pozor! Pokud se jména shodují, budou soubory přepsány!',
    },
    cropper: {
      title: 'Oříznutí',
      apply: 'Aplikovat',
      reset: 'Obnovit',
      save: 'Uložit',
    },
  },
  notifications: {
    cutToClipboard: 'Vyjmuto do schránky!',
    copyToClipboard: 'Zkopírováno do schránky!',
  },
  response: {
    noConfig: 'Konfigurace nebyla nalezena!',
    notFound: 'Nenalezeno!',
    diskNotFound: 'Disk nebyla nalezen!',
    pathNotFound: 'Cesta nebyla nalezena!',
    diskSelected: 'Disk byl vybrán!',
    // files
    fileExist: 'Soubor již existuje!',
    fileCreated: 'Soubor byl vytvořen!',
    fileUpdated: 'Soubor byl aktualizován!',
    fileNotFound: 'Soubor nebyl nalezen!',
    // directories
    dirExist: 'Složka již existuje!',
    dirCreated: 'Složka byla vytvořena!',
    dirNotFound: 'Složka nebyla nalezena',
    // actions
    uploaded: 'Všechny soubory byly nahrány!',
    notAllUploaded: 'Některé soubory nebyly nahrány!',
    delNotFound: 'Některé položky nebyly nalezeny!',
    deleted: 'Smazáno!',
    renamed: 'Přejmenováno!',
    copied: 'Úspěšně zkopírováno!',
    // zip
    zipError: 'Chyba při vytváření archivu!',
    // acl
    aclError: 'Přístup odepřen!',
  },
};

export default cs;
