
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state(){
    return {
      topDownloaders:[],
      fileDownloads:[],
      fileShared:[],
      userDownloads:[],
      userSharing:[]
    }
    
  },
  actions,
  mutations
};
