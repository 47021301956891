import axios from "axios"

export default {
    login({commit}, user) {
        return new Promise((resolve, reject) => {
          commit('AUTH_REQUEST')
          axios.post('farmer_admin/auth', user)
          .then(resp => {
            if(resp.data.success === 0 ){
                commit('AUTH_ERROR')
                reject(resp.data.error)
            } else {
              const user = resp.data.user
              
              localStorage.setItem('LoggedUser', btoa(JSON.stringify(user)))
              commit('AUTH_SUCCESS', { user: user })
              resolve(resp)
            }
          })
          .catch(err => {
            commit('AUTH_ERROR')
            localStorage.removeItem('LoggedUser')
            reject(err)
          })
        })
      },

      logout({commit}) {
        return new Promise((resolve, reject) => {
          commit('LOGOUT')
          localStorage.removeItem('LoggedUser')
          resolve()
        })
      }

     

}
