export default {
    AUTH_REQUEST(state) {
        state.status = 'loading'
    },
    AUTH_SUCCESS(state, payload) {
        state.status = 'success'
        state.user = payload.user
    },
    AUTH_ERROR(state) {
        state.status = 'error'
    },
    LOGOUT(state) {
        state.status = ''
        state.token = ''
        state.user = ''
    },
}
