import mutations from './mutations';
import actions from './actions';

export default {
    namespaced: true,
    state(){
        return {
            news: [],
            loading: true,   
        };
    },
    mutations,
    actions,
   
}
