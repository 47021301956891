import axios from "axios"

export default {
    list({commit}) {
        return new Promise((resolve, reject) => {
          axios.get('farmer/news')
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
              commit('setNews',{ response: resp.data })
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      get({commit}, form) {
        return new Promise((resolve, reject) => {
          axios.get(`farmer/news/${form.id}`)
              .then(resp => {
                if(resp.data.success === 0 ){
                  reject(resp.data.error)
                } else {
                  //commit('setForm', resp.data)
                  resolve(resp.data)
                }
              })
              .catch(err => {
                reject(err)
              })
        })
      },
      store({commit}, form) {
        return new Promise((resolve, reject) => {
          axios.post(`farmer/news`, form, { headers: {'Content-Type': 'multipart/form-data'}})
              .then(resp => {
                if(resp.data.success === 0 ){
                  reject(resp.data.error)
                } else {
                  resolve(resp)
                }
              })
              .catch(err => {
                reject(err)
              })
        })
      },
      destroy({commit}, form) {
        return new Promise((resolve, reject) => {
          axios.delete(`farmer/news/${form.id}`)
              .then(resp => {
                if (resp.data.success === 0) {
                  reject(resp.data.error)
                } else {
                  resolve(resp)
                }
              })
              .catch(err => {
                reject(err)
              })
        })
      },
      
      update({commit}, form) {
          return new Promise((resolve, reject) => {
            axios.post(`farmer/news/${form.id}`, form.data, {headers:{'Content-Type': 'multipart/form-data'}})
                .then(resp => {
                  if(resp.data.success === 0 ){
                    reject(resp.data.error)
                  } else {
                    resolve(resp.data)
                  }
                })
                .catch(err => {
                  reject(err)
                })
          })
  },

}
