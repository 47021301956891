import axios from "axios"


export default {
    getList({commit}) {
        return new Promise((resolve, reject) => {
          commit('CATEGORY_REQUEST')
          axios.get('farmer/all_categories')
          .then(resp => {
            if(resp.data.success === 0 ){
                commit('CATEGORY_REQUEST_ERROR')
                reject(resp.data.error)
            } else {
            
              commit('CATEGORY_REQUEST_SUCCESS', { response: resp.data })
              resolve(resp.data)
            }
          })
          .catch(err => {
            commit('CATEGORY_REQUEST_ERROR')
            reject(err)
          })
        })
      },

      getOne({commit},category) {
        return new Promise((resolve, reject) => {
          axios.get(`farmer/category/${category.id}`)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },

      update({commit},category) {
        return new Promise((resolve, reject) => {
          axios.put(`farmer/category/${category.id}`,category )
         // axios({url:`farmer/category/${category.id}`, data:category , method: 'PUT', baseURL: endpoint})
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      store({commit}, category) {
       console.log(category);
        return new Promise((resolve, reject) => {
          axios.post(`farmer/category`, category)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      remove({commit},category) {
        return new Promise((resolve, reject) => {
          axios.delete(`farmer/category/${category.id}`)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      }

}
