import mutations from './mutations';
import getters from './getters';
import actions from './actions';


export default {
    namespaced: true,
    state(){
        return {
            categories: [],
            form: { name:'', id: 0}
           
        };
    },
    getters,
    mutations,
    actions,
   
}
