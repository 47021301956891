// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import endpoint from './api/endpoint'


import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import Vuex from 'vuex'


import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );
// store
import fm from './store';
import axios from 'axios';

Vue.use(Vuex)

//import SweetAlertVuetify from 'sweet-alert-vuetify';
//Vue.use(SweetAlertVuetify, vuetify);
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);


import BootstrapVue from 'bootstrap-vue' //Importing
Vue.use(BootstrapVue);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);


import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)


//Vue.component("downloadExcel", JsonExcel);

//import VueJsonToCsv from 'vue-json-to-csv'
//Vue.use(VueJsonToCsv);

//import Form from "./utilities/Form";
//window.Form = Form;

const store = new Vuex.Store({
  // eslint-disable-next-line no-undef
  strict: process.env.NODE_ENV !== 'production',
  modules: {fm},
})

//Vue.use(ckeditor);

Vue.config.productionTip = true;
axios.defaults.baseURL = endpoint;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
