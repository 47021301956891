import axios from "axios"

export default {
    getList({commit}) {
        return new Promise((resolve, reject) => {
          commit('FARMERS_REQUEST')
          axios.get('farmer/user')
          .then(resp => {
            if(resp.data.success === 0 ){
                commit('FARMERS_REQUEST_ERROR')
                reject(resp.data.error)
            } else {
            
              commit('FARMERS_REQUEST_SUCCESS', { response: resp.data })
              resolve(resp.data)
            }
          })
          .catch(err => {
            commit('FARMERS_REQUEST_ERROR')
            reject(err)
          })
        })
      },
      loginLogs({commit}, user){
        return new Promise((resolve, reject) => {
          axios.get(`farmer/login-logs/${user.id}`)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
              commit('LOGIN_LOGS', { response: resp.data })
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },

      getOne({commit}, farmer ) {
        return new Promise((resolve, reject) => {
          axios.get(`farmer/user/${farmer.id}`)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },

      update({commit}, farmer) {
        return new Promise((resolve, reject) => {
          axios.put(`farmer/user/${farmer.id}`, farmer)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      store({commit}, farmer) {
        return new Promise((resolve, reject) => {
          axios.post(`farmer/user`, farmer)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      remove({commit}, farmer) {
        return new Promise((resolve, reject) => {
          axios.delete(`farmer/user/${farmer.id}`)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
            
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
        })
      },

      userLogin({commit}) {
        return new Promise((resolve, reject) => {
          axios.get(`farmer/user-login`)
          .then(resp => {
            if(resp.data.success === 0 ){
                reject(resp.data.error)
            } else {
              resolve(resp.data)
            }
          })
          .catch(err => {
            reject(err)
          })
    
        });
      }

}
